import React from 'react';
import '../CSS/IconDisplay.css'; // Import the CSS file for this component
import icon from '../assets/PlayIcon.png'; // Adjust the path according to your project structure
import backgroundVideo from '../assets/banner-video.mp4';
import '../CSS/video.css'

const Banner = () => (
  
  // <div className="icon-display">
  //   <div style={{display:"flex",justifyContent:"center", width:"100%"}} className='icon-display-img'>
    
  //   <img src={icon} alt="Icon" />
  //   </div>
   
  // </div>

  <div className="video-player">
    
    <video className="responsive-video"  autoPlay muted>
        <source src={backgroundVideo} type={'video/mp4'} />
        Your browser does not support the video tag.
      </video>

  </div>
);

export default Banner;
